const blockCtaScrollReveal = () => {
  ScrollReveal().reveal(".block-cta", { delay: 400 }); // eslint-disable-line
  ScrollReveal().reveal(".block-cta .block-title", { delay: 700 }); // eslint-disable-line
  ScrollReveal().reveal(".block-cta .subtitle", { delay: 1000 }); // eslint-disable-line
  ScrollReveal().reveal(".block-cta .btn", { delay: 1300 }); // eslint-disable-line
};

document.addEventListener("DOMContentLoaded", () => {
  blockCtaScrollReveal();
});
